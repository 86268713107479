import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#FF5C39';
const footerRelatedLinks = [
	{
		title: 'Montreux Jazz Festival',
		url: '/fr/projets/montreux-jazz-festival/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-cover.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-ADN-FR.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-logo.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-pattern-FR.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-poster.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-memento-3.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/etvj/img-ETVJ-pochette-fiche.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-roll-up-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-roll-up-2.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: { eq: "projects/etvj/img-ETVJ-photo-1.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/ecole-technique-de-la-vallee-de-joux/',
					lang: 'en',
				},
			]}
			title="Ecole Technique de la Vallée de Joux - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Ecole Technique de la Vallée de Joux"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Valoriser la technicité et l’héritage emblématique
							des formations de la Vallée de Joux.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Brand Workshop',
								'Branding',
								'Identité visuelle',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le contexte</h3>
						<p>
							Après des années avec une identité qui n’était plus
							représentative de la réalité de l’École technique de
							la Vallée de Joux, il devenait nécessaire de
							repositionner la marque en définissant une identité
							visuelle forte participant à favoriser
							l’attractivité du pôle technique dans cette région
							magnifique, bien qu’excentrée.
						</p>

						<h3>Notre réponse</h3>
						<p>
							Poser des marqueurs visuels forts et à la fois
							respectueux du caractère historique des métiers et
							du savoir-faire horloger. Notre volonté a été de
							poser les fondations d’une identité pragmatique par
							sa simplicité, cherchant non seulement à lui donner
							un caractère emblématique, mais aussi du dynamisme,
							afin de participer à l’attractivité des formations.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>ADN de la marque</h3>
							<p>
								Inspirer la jeunesse tout en étant réaliste et
								pragmatique pour transmettre le caractère et le
								savoir-faire. C’est tout l’enjeu de la
								retranscription visuelle de l’ETVJ.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="ADN de la marque"
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text text-content">
						<h3>Une signature minimaliste et helvétique</h3>
						<p>
							La marque désire appuyer son héritage de la qualité
							suisse et du savoir-faire horloger qu’elle incarne
							depuis 1901. Le logo se veut simple et élégant,
							s’appuyant sur l’intemporalité des linéales
							helvétiques.
						</p>
						<p>
							Le style minimaliste du logo offre la place à
							l’identité visuelle de s’exprimer avec caractère en
							représentant les métiers et les étudiants.
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Un glossaire visuel technique et esthétique</h3>
						<p>
							Les visuels sont à la fois descriptifs et
							symboliques de l’identité de la marque. Ils
							deviennent des marqueurs forts et distinctifs de
							l’identité visuelle, exprimant la minutie et la
							sensibilité esthétique des métiers.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_3.childImageSharp}
							alt="Éléments graphiques"
						/>
						<Video
							src="708ad301ca2bdc560bad5094decf680c"
							alt=""
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_4.childImageSharp}
					alt="Posters"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_5.childImageSharp} alt="Memento" />
					<Image {...data.image_6.childImageSharp} alt="Memento" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Memento"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Alliance de l’esthétique et de la technicité pour proposer le meilleur du savoir-faire combier."
					more="Les visuels font la part belle à l’espace, ils sont associés à des formes d’une grande finesse, ceci pour exprimer la maitrise technique derrière la part créative."
					cite=""
				/>

				<Image
					className="col-full"
					{...data.image_8.childImageSharp}
					alt="Pochette et fiches"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_9.childImageSharp} alt="Roll up" />
					<Image {...data.image_10.childImageSharp} alt="Roll up" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_11.childImageSharp}
					alt="P"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
